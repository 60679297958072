import {
  handleTimeSeriesTableFilters,
  validateAndGenerateParams,
} from "helpers/marketAnalysisHelper";
import api from "./api";

const MARKET_ANALYSIS_END_POINT = "market-analysis";
const TIME_SERIES_END_POINT = `${MARKET_ANALYSIS_END_POINT}/time-series`;
const MARKET_ANALYSIS_GRAPH_END_POINT = `${MARKET_ANALYSIS_END_POINT}/graphs`;
const EXPORT_MARKET_ANALYSIS_END_POINT = `${MARKET_ANALYSIS_END_POINT}/export`;
const FILTERS_GRAPH_END_POINT = `${MARKET_ANALYSIS_GRAPH_END_POINT}/filters`;
const TABLE_FILTER_END_POINT = `${MARKET_ANALYSIS_END_POINT}/get-table-filters`;
const TIME_SERIES_DELTA_END_POINT = `${MARKET_ANALYSIS_END_POINT}/time-series/deltas`;
const TIME_SERIES_TENORS_END_POINT = `${MARKET_ANALYSIS_END_POINT}/time-series/tenors`;
const TIME_SERIES_PERIOD_TYPES_END_POINT = `${MARKET_ANALYSIS_END_POINT}/time-series/period-types`;

export const exportMarketAnalysis = async (params) => {
  return await api.get(EXPORT_MARKET_ANALYSIS_END_POINT, { ...params });
};

export const fetchMarketAnalysisGraph = async (param) => {
  return await api.get(MARKET_ANALYSIS_GRAPH_END_POINT, { ...param });
};

export const fetchMarketAnalysisTableData = async (queryParams) => {
  return await api.get(MARKET_ANALYSIS_END_POINT, { ...queryParams });
};
export const fetchTimeSeriesTable = async (queryParams) => {
  handleTimeSeriesTableFilters(queryParams);
  return await api.get(`${TIME_SERIES_END_POINT}`, { ...queryParams });
};

export const fetchMarketAnalysisFilters = async () => {
  return await api.get(FILTERS_GRAPH_END_POINT);
};

export const fetchMarketAnalysisTableFilters = async () => {
  return await api.get(TABLE_FILTER_END_POINT);
};
export const fetchTimeSeriesDeltas = async () => {
  return await api.get(TIME_SERIES_DELTA_END_POINT);
};
export const fetchTimeSeriesTenors = async (queryParams) => {
  return await api.get(TIME_SERIES_TENORS_END_POINT, { ...queryParams });
};
export const fetchTimeSeriesPeriodTypes = async (queryParams) => {
  return await api.get(TIME_SERIES_PERIOD_TYPES_END_POINT, { ...queryParams });
};
