/* eslint-disable react-hooks/exhaustive-deps */
import Highcharts from "highcharts";
import  { useContext, useEffect,memo } from "react";
import { Col, Row } from "antd";
import VolSurfaceOptionsGraph from "./VolSurfaceOptionsGraph";
import { VolSurfaceContext } from "contexts/VolSurfaceContext";
import { useVolSurfaceControlTable } from "contexts/VolSurfaceControlTableContext";
import { batchUpdateGraphs, getControlTableData, getGraphsData } from "api/volSurfaceApi";
import { toast } from "helpers/generalHelper";
import useDebounce from "hooks/useDebounce";

 function GraphsContainer({
   scrollRef,
   selectedFilters,
   setIsRiskFreeRevert,
   setBatchUpdate,
   start_date,
   end_date,
   setBiasIncreaseDecrease,
   biasIncreaseDecrease,
   isUpdatedComparisonSurface,
   setComparisonSurfaceId,
 }) {
   const {
     state: { volSurfaceData },
     setVolSurfaceData,
     setVolSurfaceBtnLoading,
   } = useContext(VolSurfaceContext);

   const {
     volSurfaceTablesData: rowsData,
     tableHeight,
     setVolSurfaceData: setRowsData,
     setVolCalcTable,
   } = useVolSurfaceControlTable();

   const debouncedBiasIncreaseDecrease = useDebounce(biasIncreaseDecrease, 500);

   useEffect(() => {
     setVolSurfaceBtnLoading(true);
     fetchGraphsData();
   }, [
     selectedFilters?.product_id,
     selectedFilters?.comparison_surface_id,
     selectedFilters?.sticky_surface,
     start_date,
     end_date,
   ]);

   // useEffect will run the effect when user click on "Whole Surface" (Biase) (Up and Down) buttons, it will update all the graphs
   useEffect(() => {
     if (
       debouncedBiasIncreaseDecrease &&
       volSurfaceData &&
       Object.keys(volSurfaceData).length > 0
     ) {
       let reqBody = {
         is_increase: debouncedBiasIncreaseDecrease > 0 ? 1 : 0,
         y_increase: Math.abs(debouncedBiasIncreaseDecrease),
         product_contract_style_id: selectedFilters?.product_id,
         risk_free: selectedFilters?.risk_free,
       };
       setBatchUpdate(true);
       batchUpdateGraphs(reqBody)
         .then((res) => {
           if (res.status === 200) {
             setBiasIncreaseDecrease(0);
             toast(res?.data?.message, "success");
             setVolSurfaceBtnLoading(true);
             fetchGraphsData();
             fetchControlTable();
           } else {
             toast(res?.data?.message, "info");
           }
         })
         .catch((error) => {
           console.log("Error while batch updating");
           setBatchUpdate(false);
           setVolSurfaceBtnLoading(false);
         });
     } else {
       setBiasIncreaseDecrease(0);
     }
   }, [debouncedBiasIncreaseDecrease, selectedFilters]);

   function fetchControlTable() {
     getControlTableData({
       product_contract_style_id: selectedFilters?.product_id,
       risk_free: selectedFilters?.risk_free || 0,
       comparison_surface_id: selectedFilters?.comparison_surface_id,
       start_date: start_date,
       end_date: end_date,
       has_update_comparison_surface: isUpdatedComparisonSurface,
     }).then((res) => {
      const data = res.data.data;
       setRowsData(data.main_control_table);
       setComparisonSurfaceId(data.comparison_surface_id);
       setVolCalcTable(data.vol_calc_table);
     });
   }

   function fetchGraphsData() {
     const params = {
       start_date: start_date,
       end_date: end_date,
       product_contract_style_id: selectedFilters?.product_id,
       comparison_surface_id: selectedFilters?.comparison_surface_id,
     };

     if (
       selectedFilters?.sticky_surface?.product_id ===
       selectedFilters?.product_id
     ) {
       params.strike_surface_id = selectedFilters?.sticky_surface?.id;
     }

     getGraphsData({ ...params })
       .then((res) => {
         if (res.status === 200) {
           const response = res.data.data;
           setVolSurfaceData(response);
           setIsRiskFreeRevert(false);
         } else {
           setIsRiskFreeRevert(true);
         }
         setVolSurfaceBtnLoading(false);
         setBatchUpdate(false);
       })
       .catch((err) => {
         setVolSurfaceBtnLoading(false);
         setBatchUpdate(false);
         console.log("Error while getting updated graphs with filters>>", err);
       });
   }

   return (
     <Col
       className="graphs-container"
       ref={scrollRef}
       style={{
         margin: "0px",
         maxHeight: tableHeight,
         overflowY: "auto",
         padding: "0px",
       }}
     >
       {volSurfaceData &&
         rowsData &&
         volSurfaceData.map((volSurf, index) => {
           return (
             <Col span={24} key={volSurf.block_id}>
               <Row gutter={[24, 4]}>
                 <Col span={24}>
                   <Row gutter={[5, 0]}>
                     {volSurf && volSurf.block_graphs?.length > 0
                       ? volSurf.block_graphs?.map((volSurfaceOptions) => {
                           return (
                             <VolSurfaceOptionsGraph
                               key={JSON.stringify(volSurfaceOptions)}
                               volSurfaceOptions={volSurfaceOptions}
                               Highcharts={Highcharts}
                             />
                           );
                         })
                       : null}
                   </Row>
                 </Col>
               </Row>
             </Col>
           );
         })}
     </Col>
   );
 }


export default memo(GraphsContainer)