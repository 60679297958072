import { Col, DatePicker, Input, Row } from "antd";
import { GlobalContext } from "contexts/GlobalContext";
import { useVolSurfaceControlTable } from "contexts/VolSurfaceControlTableContext";
import moment from "moment";
import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function SelectDate({
  setDateTime,
  dateTime,
  setSelectedFilters,
  setIsCalculationDateChanged,
}) {
  const {
    globalState: { dark_Theme },
  } = useContext(GlobalContext);
  const location = useLocation();
  const { stickyStrikeData } = useVolSurfaceControlTable();

  const pathName = location.pathname;
  const isVolSurfacePage = pathName.includes("vol-surface");
  const hideDateFilterOnProdForVolSurface = !isVolSurfacePage;

  const handleRangePickerChange = (dateStr) => {
    setDateTime(dateStr);
    setIsCalculationDateChanged(1);
    setSelectedFilters((prev) => ({
      ...prev,
      strike_date: dateStr ? moment(dateStr).format("YYYY-MM-DD HH:mm:ss") : "",
    }));
  };



  const dateValue = () => {
    if (dateTime) {
      return moment(dateTime);
    } else {
      return "";
    }
  };

  return (
    <>
      <Col style={{ width: "100%" }} span={24}>
        {!hideDateFilterOnProdForVolSurface && (
          <Row
            style={{ width: "100%" }}
            size={"middle"}
            className={`${dark_Theme && "date-picker-dark"}`}
          >
            <Col span={3}>
              <span style={{ whiteSpace: "nowrap" }}>
                Date/Time of Surface:
              </span>
            </Col>
            <Col>
              <DatePicker
                onChange={handleRangePickerChange}
                className={
                  dateTime &&
                  stickyStrikeData?.should_calculation_date_be_yellow &&
                  "filled--data_rangePicker__background"
                }
                popupClassName={dark_Theme && "date-picker-dropdown-dark"}
                value={dateValue()}
                showTime
              />
            </Col>
            <Col offset={1} span={3}>
              <span style={{ whiteSpace: "nowrap" }}>
                Current EOD Surface Time:
              </span>
            </Col>
            <Col>
              <Input value={stickyStrikeData?.time} disabled />
            </Col>
          </Row>
        )}
      </Col>
    </>
  );
}
