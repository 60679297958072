import {
  fetchMarketAnalysisTableData,
  fetchTimeSeriesTable,
} from "api/marketAnalysisApi";
import { createQueryParamsForSSRM } from "./generalHelper";
export const MARKET_ANALYSIS_FILTERS = "market_analysis_filters";

/**
 * Clones the options to avoid direct mutation based on custom_grouping_type.
 * @param {Object} options - The original options object to be modified.
 * @return {Object} The modified options object with updated formatters.
 */
export const modifyChartOptionsForGrouping = (options) => {
  // Clone the options to avoid direct mutation
  const modifiedOptions = JSON.parse(JSON.stringify(options));

  switch (modifiedOptions.custom_grouping_type) {
    case "quarterly":
      modifiedOptions.xAxis.labels.formatter = function () {
        const date = new Date(this.value);
        const quarter = Math.floor(date.getMonth() / 3) + 1;
        return "Q" + quarter + " " + date.getFullYear().toString().slice(-2);
      };
      modifiedOptions.tooltip.formatter = function () {
        const date = new Date(this.point.x);
        const quarter = Math.floor(date.getMonth() / 3) + 1;
        return (
          "<b>" +
          this.series.name +
          "</b><br>x: Q" +
          quarter +
          " " +
          date.getFullYear().toString().slice(-2) +
          "<br>y: " +
          this.point.y
        );
      };
      break;
    case "yearly":
      modifiedOptions.xAxis.labels.formatter = function () {
        const date = new Date(this.value);
        return date.getFullYear().toString();
      };
      modifiedOptions.tooltip.formatter = function () {
        const date = new Date(this.point.x);
        return (
          "<b>" +
          this.series.name +
          "</b><br>x: " +
          date.getFullYear().toString() +
          "<br>y: " +
          this.point.y
        );
      };
      break;
    case "monthly":
      modifiedOptions.xAxis.labels.formatter = function () {
        const date = new Date(this.value);
        const month = date.toLocaleString("default", { month: "short" });
        return month + "-" + date.getFullYear().toString().slice(-2);
      };
      modifiedOptions.tooltip.formatter = function () {
        const date = new Date(this.point.x);
        const month = date.toLocaleString("default", { month: "short" });
        return (
          "<b>" +
          this.series.name +
          "</b><br>x: " +
          month +
          "-" +
          date.getFullYear().toString().slice(-2) +
          "<br>y: " +
          this.point.y
        );
      };
      break;
    case "daily":
      modifiedOptions.xAxis.labels.formatter = function () {
        const date = new Date(this.value);
        // Using English locale and specifying day, month, and year options
        return date.toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "short",
          year: "2-digit",
        });
      };
      modifiedOptions.tooltip.formatter = function () {
        const date = new Date(this.point.x);
        // Using English locale and specifying day, month, and year options
        return (
          "<b>" +
          this.series.name +
          "</b><br>x: " +
          date.toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "short",
            year: "2-digit",
          }) +
          "<br>y: " +
          this.point.y
        );
      };
      break;

    default:
      // Reset formatters for other grouping modes
      delete modifiedOptions.xAxis.labels.formatter;
      modifiedOptions.tooltip.formatter = undefined;
      break;
  }

  return modifiedOptions;
};

const generateFilterParamsDataSource = (
  params,
  sort_model,
  start_date,
  end_date,
  filterModel
) => {
  const requestParams = params.request;
  const startRow = requestParams.startRow;
  const endRow = requestParams.endRow;
  const groupKeys = JSON.stringify(requestParams.groupKeys || []);

  const sortModel = sort_model
    ? JSON.stringify(sort_model)
    : JSON.stringify(requestParams.sortModel || []);

  const queryParams = createQueryParamsForSSRM(
    startRow,
    endRow,
    sortModel,
    groupKeys,
    filterModel
  );

  let dateFilter = {
    start_date,
    end_date,
  };

  return { queryParams, dateFilter };
};

export const createServerSideDataSource = ({
  filterModelData,
  sort_model,
  start_date,
  end_date,
}) => {
  return {
    getRows: (params) => {
      const filterModel = JSON.stringify({
        ...filterModelData,
        ...(params.request.filterModel || []),
      });

      const { queryParams, dateFilter } = generateFilterParamsDataSource(
        params,
        sort_model,
        start_date,
        end_date,
        filterModel
      );

      fetchMarketAnalysisTableData({ ...queryParams, ...dateFilter })
        .then((response) => {
          params.success({
            rowData: response.data.data.rows,
            rowCount: response.data.data.lastRow,
          });
        })
        .catch((error) => {
          console.error("Error fetching market-analysis data:", error);
          params.fail();
        });
    },
  };
};
export const createTimeSeriesServerSideDataSource = ({
  filterModelData,
  sort_model,
  start_date,
  end_date,
  filters,
}) => {
  return {
    getRows: (params) => {
      const filterModel = JSON.stringify({
        ...filterModelData,
        ...(params.request.filterModel || []),
      });

      const { queryParams, dateFilter } = generateFilterParamsDataSource(
        params,
        sort_model,
        start_date,
        end_date,
        filterModel
      );
      console.log(filters[0]);

      if (filters[0].metric === "delta") {
        filters[0].metric_value = filters[0].x_axis;
      } else if (filters[0].metric === "strike") {
        filters[0].metric_value = filters[0].strike;
      }

      if (filters[0].analysis_style === "tenor") {
        delete filters[0].period_number;
      } else if (filters[0].analysis_style === "period") {
        delete filters[0].style_period_tenor_id;
      }

      delete filters[0].x_axis;
      delete filters[0].strike;
      delete filters[0].id;

      fetchTimeSeriesTable({
        ...queryParams,
        ...dateFilter,
        ...filters[0],
      })
        .then((response) => {
          params.success({
            rowData: response.data.data.rows,
            rowCount: response.data.data.lastRow,
          });
        })
        .catch((error) => {
          console.error("Error fetching market-analysis data:", error);
          params.fail();
        });
    },
  };
};

export const createServerDataSourceParams = {
  gridRef: null,
  filterModelData: null,
  sort_model: null,
  start_date: null,
  end_date: null,
};

export function createServerDataSource(createServerDataSourceParams) {
  const { gridRef } = createServerDataSourceParams;
  delete createServerDataSourceParams.gridRef;
  gridRef.current.api?.setServerSideDatasource(
    createServerSideDataSource({
      ...createServerDataSourceParams,
    })
  );
}

export function createTimeSeriesServerDataSource(createServerDataSourceParams) {
  const { gridRef } = createServerDataSourceParams;
  delete createServerDataSourceParams.gridRef;
  gridRef.current.api?.setServerSideDatasource(
    createTimeSeriesServerSideDataSource({
      ...createServerDataSourceParams,
    })
  );
}

const requiredFields = [
  "table_id",
  "product_contract_style_id",
  "start_date",
  "end_date",
  "metric",
  "period_type_id",
  "analysis_style",
  "show_historic_vol",
  "rolling_avg_days",
];

export function validateTimeSeriesFilters(data) {
  // Check if all required fields are filled (not null, undefined, or empty string)
  for (let field of requiredFields) {
    if (
      data[field] === null ||
      data[field] === undefined ||
      data[field] === ""
    ) {
      return false;
    }
  }

  // Special conditions for metric
  if (data.metric === "delta") {
    if (!data.x_axis) {
      return false;
    }
  } else if (data.metric === "strike") {
    if (!data.strike && data.strike !== 0) {
      return false;
    }
  }

  // Special conditions for analysis_style
  if (data.analysis_style === "style_period_tenor_id") {
    if (!data.style_period_tenor_id) {
      return false;
    }
  } else {
    if (!data.period_number && data.period_number !== 0) {
      return false;
    }
  }

  return true;
}

export function validateAndGenerateParams(queryParams) {
  if (!Array.isArray(queryParams) || queryParams.length === 0) {
    return null; // Ensure queryParams is an array and not empty
  }
  const params = new URLSearchParams();
  const isValid = queryParams.every((filter, index) => {
    // Check required fields (should not be null or undefined)
    for (let field of requiredFields) {
      if (filter[field] == null) {
        return false;
      }
    }
    // Special conditions for metric
    if (filter.metric === "delta") {
      if (filter.x_axis == null) {
        return false;
      }
      // If metric = "delta", we REMOVE 'strike'
      delete filter.strike;
    } else if (filter.metric === "strike") {
      if (filter.strike == null) {
        return false;
      }
      // If metric = "strike", we REMOVE 'x_axis'
      delete filter.x_axis;
    }
    // Special conditions for analysis_style
    if (filter.analysis_style === "style_period_tenor_id") {
      if (filter.style_period_tenor_id == null) {
        return false;
      }
      // If analysis_style = "tenor", we REMOVE 'period_number'
      delete filter.period_number;
    } else {
      if (filter.period_number == null) {
        return false;
      }
      // If analysis_style != "tenor", we REMOVE 'tenor'
      delete filter.style_period_tenor_id;
    }

    // Add valid params to URLSearchParams
    Object.entries(filter).forEach(([key, value]) => {
      if (value != null && value !== "") {
        // Only add valid values
        params.append(
          `filters[${index}][ ${
            key === "x_axis" || key === "strike" ? "metric_value" : key
          }]`,
          value
        );
      }
    });

    return true; // Continue validation for all filters
  });

  return isValid ? params.toString() : null; // Return query string if valid, otherwise null
}

export function handleTimeSeriesTableFilters(queryParams) {
  if (queryParams.metric === "delta") {
    queryParams.metric_value = queryParams.x_axis;
  } else if (queryParams.metric === "strike") {
    queryParams.metric_value = queryParams.strike;
  }

  if (queryParams.analysis_style === "tenor") {
    delete queryParams.period_number;
  } else if (queryParams.analysis_style === "period") {
    delete queryParams.style_period_tenor_id;
  }

  delete queryParams.x_axis;
  delete queryParams.strike;

}