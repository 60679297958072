import { getVolSurfaceStickyStrikes } from "api/volSurfaceApi";
import { GlobalContext } from "contexts/GlobalContext";
import { useVolSurfaceControlTable } from "contexts/VolSurfaceControlTableContext";
import { useContext, useEffect, useState } from "react";

function useStrikeTable() {
  const [isFetching, setIsFetching] = useState(false);
  const [gridHeight, setGridHeight] = useState("auto");

  const {
    globalState: { dark_Theme },
  } = useContext(GlobalContext);
  const {
    selectedFilters,
    stickyStrikeData,
    setStickyStrikeData,
    isCalculationDateChanged,
  } = useVolSurfaceControlTable();

  useEffect(() => {
    if (!selectedFilters?.product_id) return;
    if (stickyStrikeData?.table?.length === 0) {
      setIsFetching(true);
    }
    getVolSurfaceStickyStrikes({
      product_contract_style_id: selectedFilters?.product_id,
      date_time: isCalculationDateChanged ? selectedFilters?.strike_date : null,
      risk_free: selectedFilters?.risk_free || 0,
    })
      .then((res) => {
        setStickyStrikeData(res.data.data);
      })
      .finally(() => {
        setIsFetching(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedFilters?.product_id,
    selectedFilters?.strike_date,
    selectedFilters?.risk_free,
    isCalculationDateChanged,
  ]);

  //handle grid height
  useEffect(() => {
    if (stickyStrikeData?.table?.length === 0) return;
    const numberOfRowData = stickyStrikeData?.table?.length;
    const calculatedHeight = numberOfRowData * 30 + 38;
    setGridHeight(`${calculatedHeight}px`);
  }, [stickyStrikeData?.table]);

  return {
    isFetching,
    gridHeight,
    dark_Theme,
  };
}

export default useStrikeTable;
